import { getToken, isTokenPresent, getExpiredTime, getRefreshToken } from "../services/Auth";
import {
    CHANGE_PASSWORD,
    CHANGE_LOGIN,
    FETCH_TOKEN,
    FETCH_TOKEN_FAIL,
    FETCH_TOKEN_SUCCESS,
    REMOVE_TOKEN,
    TOGGLE_REMEMBER_SESSION,
    SET_EXPIRED_TIME,
    SET_PAGE_RELOADED,
    SET_TOKEN_IN_HEADERS,
    CHECK_IF_TOKEN_EXPIRED,
    ON_FIRE_REFRESH_TOKEN
} from "../actions";

const initialState = {
    isFetching: null,
    token: isTokenPresent() ? getToken() : "",
    expiredTime: getExpiredTime() || "",
    hasError: false,
    errorMessage: "",
    rememberSession: true,
    login: "",
    password: "",
    // token: "",
    accessToken: isTokenPresent() ? getToken() : "",
    refreshToken: getRefreshToken() || "",
    expiresIn: null,
    reloadPage: true,
    tokenInHeaders: false,
    isTokenExpired: true,
    fireRefreshToken: false
};

export default function TokenReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TOKEN:
            return Object.assign({}, state, {
                ...state,
                isFetching: true,
            });
        case SET_TOKEN_IN_HEADERS:
            return Object.assign({}, state, {
                ...state,
                tokenInHeaders: action.payload,
            });
        case CHECK_IF_TOKEN_EXPIRED:
            return Object.assign({}, state, {
                ...state,
                isTokenExpired: action.payload,
            });
        case ON_FIRE_REFRESH_TOKEN:
            return Object.assign({}, state, {
                ...state,
                fireRefreshToken: action.payload,
            });

        case SET_PAGE_RELOADED:
            return Object.assign({}, state, {
                ...state,
                reloadPage: action.payload,
            });

        case FETCH_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                // data: action.payload,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                expiresIn: action.payload.expiresIn,
                hasError: false,
                errorMessage: "",
            });
        case SET_EXPIRED_TIME:
            return Object.assign({}, state, {
                isFetching: false,
                expiredTime: action.payload
            });
        case FETCH_TOKEN_FAIL:
            return Object.assign({}, state, {
                isFetching: false,
                data: "",
                hasError: true,
                errorMessage: action.payload,
            });
        case REMOVE_TOKEN:
            return Object.assign({}, state, {
                ...initialState,
                data: "",
            });
        case TOGGLE_REMEMBER_SESSION:
            return Object.assign({}, state, {
                ...state,
                rememberSession: !state.rememberSession,
            });
        case CHANGE_LOGIN:
            return Object.assign({}, state, {
                ...state,
                login: action.payload,
            });
        case CHANGE_PASSWORD:
            return Object.assign({}, state, {
                ...state,
                password: action.payload,
            });
        default:
            return state;
    }
}