/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios, { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import dayjs from "dayjs";
import { amountFormatter } from "../../constants";
import styles from "./transactions-details.module.scss";
import { enqueueSnackbar } from "notistack";
import Button from "@mui/material/Button";
import { GenerateIcon } from "../../svg-icons/icon-generate";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Switch, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TerminalsDetails = () => {
  const { t } = useTranslation();
  const { txId } = useParams();
  const defaultTrxInfo = {
    id: null,
    posId: null,
    terminalId: null,
    sellerId: null,
    sellerName: null,
    storeId: null,
    storeName: null,
    amount: null,
    ccy: null,
    mccCode: null,
    referenceNumber: null,
    approvalCode: null,
    status: null,
    comment: null,
    txDate: null,
    merchantStoreName: null,
    merchantStoreAddress: null,
    swiftMessageType: null,
    swiftMessageId: null,
    swiftPayerBank: null,
    paymentSystem: null,
  };

  const revertSummDataInfo = {
    revertReason: "",
    revertReasonDescription: "",
    isPartial: false,
    partialSumm: "",
  };

  const [isFetching, setIsFetching] = useState(true);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [isReverting, setIsReverting] = useState(false);
  const [transactionInfo, setTrxInfo] = useState({ ...defaultTrxInfo });
  const [revertSummData, setRevertSummData] = useState({
    ...revertSummDataInfo,
  });
  const [redirect, setRedirect] = useState(false);
  const [resonList, setReasonList] = useState([]);
  const [trxRequestId, setTrxRequestId] = useState(null);
  const [checkTrxStatus, setCheckTrxStatus] = useState(5);
  const [checkCounter, setCheckCounter] = useState(0);
  const [redirectTrxId, setRedirectTrxId] = useState(null);

  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    if (trxRequestId) {
      const checkTrx = setInterval(() => {
        setCheckTrxStatus((prevProgress) => prevProgress - 1);
      }, 1000);

      if (checkTrxStatus === 0) {
        // setCheckTrxStatus(5)
        // setCheckCounter(checkCounter + 1)
        axios
          .get(`/cap/api/v1/tx/return/${trxRequestId}/status`)
          .then((res) => {
            setCheckCounter(checkCounter + 1);
            if (res.data.status === "confirmed") {
              setRedirectTrxId(res.data.txId);
              enqueueSnackbar(t("Trx_revert_confirmed"), {
                variant: "success",
              });

              setTimeout(() => {
                setRedirect(true);
                resetDataRevertModal();
              }, 500);
            } else if (res.data.status === "processing") {
              setCheckTrxStatus(5);
            } else if (res.data.status === "declined") {
              enqueueSnackbar(t("Trx_revert_declined"), { variant: "error" });
              resetDataRevertModal();
            } else if (res.data.status === "error") {
              enqueueSnackbar(t("Trx_revert_error"), { variant: "error" });
              resetDataRevertModal();
            }
          }).catch(() => {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          });
      }

      return () => {
        clearInterval(checkTrx);
      };
    }
  }, [trxRequestId, checkTrxStatus]);

  useEffect(() => {
    if (checkCounter === 10) {
      resetDataRevertModal();
      enqueueSnackbar(t("Trx_check_status_in_table"), { variant: "error" });
      setRedirect(true);
    }
  }, [checkCounter]);

  useEffect(() => {
    const getReasonsList = async () => {
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/tx/return/reason`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setReasonList(data.reasonList);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      } finally {
        setIsFetching(false);
      }
    };
    showRevertModal && resonList.length === 0 && getReasonsList();
  }, [showRevertModal]);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setIsFetching(true);
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/tx/${txId}`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setTrxInfo(data);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      } finally {
        setIsFetching(false);
      }
    };

    accessToken && fetchTransactionDetails();
  }, [accessToken, txId]);

  const navBarTitle = `#${txId}`;

  const breadcrumbs = {
    step1Label: t("Transactions"),
    step1Url: "/app/transactions",
    step2Label: `#${txId}`,
  };

  const onChange = (state, value) => {
    let data = { ...revertSummData };
    if (state === "isPartial") {
      if (!value) {
        data = {
          ...revertSummData,
          partialSumm: "",
          [state]: value,
        };
        setRevertSummData(data);
      } else {
        data = {
          ...revertSummData,
          [state]: value,
        };
        setRevertSummData(data);
      }
    } else if (state === "partialSumm") {
      if ((value !== "-" || value === "") && value[value.length - 1] !== "-") {
        data = {
          ...revertSummData,
          partialSumm: "",
          [state]: value,
        };
        setRevertSummData(data);
      }
    } else {
      data = {
        ...revertSummData,
        [state]: value,
      };

      setRevertSummData(data);
    }
  };
  const onRevertCommision = async () => {
    setIsReverting(true);

    try {
      const body = {
        txId,
        amount: !revertSummData.isPartial
          ? transactionInfo.amount
          : revertSummData.partialSumm,
        fullRefund: !revertSummData.isPartial,
        ccy: transactionInfo.ccy,
        reasonCode: revertSummData.revertReason,
        reasonMessage: revertSummData.revertReasonDescription,
      };
      const { status, response } = await apiRequest(
        "post",
        `/cap/api/v1/tx/return`,
        body
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        if (data.result === "success") {
          enqueueSnackbar(t("Wait_for_a_trx_finish"), { variant: "warning" });
          setTrxRequestId(data.requestId);
        } else if (data.result === "tx_not_found") {
          enqueueSnackbar(t("Trx_not_found"), { variant: "error" });
        } else if (data.result === "return_forbidden") {
          enqueueSnackbar(t("Trx_return_forbidden"), { variant: "warning" });
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch transaction details:", error);
    } finally {
      setIsReverting(false);
    }
  };

  const disabledButton =
    !revertSummData.revertReason ||
    !revertSummData.revertReasonDescription ||
    (revertSummData.isPartial && !revertSummData.partialSumm) ||
    (revertSummData.isPartial &&
      Number(revertSummData.partialSumm) > transactionInfo.amount);

  const resetDataRevertModal = () => {
    setRevertSummData({ ...revertSummDataInfo });
    setTrxRequestId(null);
    // setRedirectTrxId(null);
    setCheckTrxStatus(5);
    setCheckCounter(0);
    setShowRevertModal(false);
  };

  const renderReasonsItems = () => {
    const content = resonList.map(({ code, title }, index) => {
      return (
        <MenuItem value={code} key={index}>
          {title}{" "}
        </MenuItem>
      );
    });
    return content;
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    const isValidInput = /^[0-9]*[.,]?[0-9]*$/.test(value);
    if (isValidInput) {
      value = value.replace(/,/g, ".");
      setRevertSummData((prevData) => ({
        ...prevData,
        partialSumm: value,
      }));
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("Text");
    if (!/^[0-9]*[.,]?[0-9]*$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const revertModal = () => {
    return (
      <Dialog
        open={showRevertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowRevertModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Revert_summ")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="revert" className={styles.logout_header}>
              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Reason")}</label>

                <FormControl>
                  <InputLabel id="selectedDecision">{t("Select")}</InputLabel>
                  <Select
                    labelId="selectedDecision-label"
                    id="selectedDecision"
                    value={revertSummData.revertReason}
                    onChange={(e) => onChange("revertReason", e.target.value)}
                  >
                    {renderReasonsItems()}
                  </Select>
                </FormControl>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="selectedDecision">
                  {t("Description_solution")}
                </label>
                <FormControl>
                  <TextField
                    id="outlined-basic"
                    label={t("We_decided_to")}
                    multiline
                    variant="outlined"
                    value={revertSummData.revertReasonDescription}
                    onChange={(e) => {
                      const value = e.target.value.replace(/^\s+/, "");
                      onChange("revertReasonDescription", value);
                    }}
                  />
                </FormControl>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">
                  {t("Is_partial_revert_summ")}
                </label>
                <Switch
                  // onChange={(e) => onChange("isPartial", e.target.checked)}
                  // checked={revertSummData.isPartial}
                  checked={false}
                />
              </div>

              {revertSummData.isPartial && (
                <div
                  className={classNames({
                    [styles.info_block_input_container]: true,
                  })}
                >
                  <label htmlFor="selectedDecision">
                    {t("Transaction_partial_summ")}
                  </label>
                  <TextField
                    id="outlined-basic"
                    label={t("Amount")}
                    multiline
                    variant="outlined"
                    disabled={!revertSummData.isPartial}
                    value={revertSummData.partialSumm}
                    onChange={handleInputChange}
                    onPaste={handlePaste}
                    helperText={
                      transactionInfo.amount < revertSummData.partialSumm &&
                      t("Partial_summ_big_that_transaction_summ")
                    }
                    error={transactionInfo.amount < revertSummData.partialSumm}
                  />
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() =>
                isReverting || disabledButton ? {} : onRevertCommision()
              }
              variant="contained"
              disabled={trxRequestId > 0}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]:
                  isReverting || disabledButton || trxRequestId > 0,
              })}
            >
              OK
              {isReverting && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => resetDataRevertModal()}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
          {trxRequestId && (
            <Box sx={{ width: "100%" }}>
              <span className={styles.waiting_for_response}>
                {t("Waiting_for_a_trx_revert_response")}
                <CircularProgress size={16} />
              </span>
              <LinearProgress variant="determinate" value={checkCounter * 10} />
            </Box>
          )}
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      {showRevertModal && revertModal()}
      <div>
        {redirect && (
          <Redirect
            to={`/app/transactions${redirectTrxId ? `/${redirectTrxId}` : ""}`}
          />
        )}
        <div
          className={classNames({
            // [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("Transactions_details")}</span>

                  {transactionInfo.status === "success" && (
                    // <Button
                    //   onClick={() => setShowRevertModal(false)}
                    //   variant="text"
                    //   className={styles.exit_button}
                    // >
                    //   {t("Revert_transaction")}
                    // </Button>
                    <Button
                      variant="text"
                      // icon={<GenerateIcon />}
                      // label={t("Revert_transaction")}
                      onClick={() => setShowRevertModal(true)}
                      className={styles.revert_button}
                    >
                      <GenerateIcon />
                      {t("Revert_transaction")}
                    </Button>
                  )}
                </div>
                <div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Date")}</label>
                    <div className={styles.block_value}>
                      {dayjs(transactionInfo.txDate).format(
                        "DD/MM/YYYY, HH:mm"
                      )}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_id")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.id}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_id")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/terminals/${transactionInfo.posId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.terminalId}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>POS ID</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/terminals/${transactionInfo.posId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.posId}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Amount")}</label>
                    <div
                      className={classNames({
                        [styles.block_value]: true,
                        [styles.table_cell_summ]: true,
                      })}
                    >
                      {amountFormatter(transactionInfo.amount)}
                      <span className={styles.uppercase}>
                        {transactionInfo.ccy}
                      </span>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Seller")}</label>
                    <div
                      className={classNames({
                        [styles.block_value]: true,
                        [styles.table_cell_summ]: true,
                      })}
                    >
                      <Link
                        to={`/app/sellers/${transactionInfo.sellerId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.sellerName}
                      </Link>
                    </div>
                  </div>

                  {/* <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Reference_number")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo?.referenceNumber || " - "}
                    </div>
                  </div> */}

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MCC")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.mccCode}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Approval_code")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.approvalCode || " - "}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Store")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/stores/${transactionInfo.storeId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.merchantStoreName || " - "}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Status")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(transactionInfo.status, t)}
                    </div>
                  </div>

                  {/* <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Comment")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.comment || " - "}
                    </div>
                  </div> */}

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_store")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.merchantStoreName || " - "}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_store_adres")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.merchantStoreAddress || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_type")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftMessageType || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_id")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftMessageId || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_bank")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftPayerBank || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_pay_system")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.paymentSystem || " - "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default TerminalsDetails;
