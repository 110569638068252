import React from 'react';
import { Button } from 'finergy-kit';
import { IButtonComponentProps } from "./types"
import classNames from "classnames"
import styles from './button.module.scss';

function ButtonComponent({ title, disabled, onClick, children, className, icon }: IButtonComponentProps) {

    return (
        <div
            className={classNames({
                [`${styles.button_wrapper}`]: true,
                'disabled': disabled,
                [className]: true
            })}>
            <Button
                className={styles.button}
                onClick={onClick}
                variant="primary"
                disabled={disabled}
                label={title}
                size="l"
                icon={icon}
                iconPosition="off"
                //iconOnly={disabled}
                id={"BTN_BUTTON_COMPONENT"}
            />

        </div>
    );
};

export default ButtonComponent;
