import React, { useEffect, useState } from 'react';
import { IEmptyPageContentProps } from "./types"
import { EmptyPageIcon } from "../../../svg-icons/icon-empty-page"
import classNames from "classnames"
import { useTranslation } from 'react-i18next';

import styles from './empty-page.module.scss';

function EmptyPageContent({ }: IEmptyPageContentProps) {

    const { t } = useTranslation();
    return (
        <div
            className={classNames({
                [`${styles.empty_page_content}`]: true,
            })}>
            <EmptyPageIcon />
            <h2>{t('Empty_title')}</h2>
            <span>{t('Empty_subtitle')}</span>
        </div>
    );
};

export default EmptyPageContent;
