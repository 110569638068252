/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import dayjs from "dayjs";
import { amountFormatter } from "../../constants";
import styles from "./rtp-details.module.scss";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { enqueueSnackbar } from "notistack";

const RTPDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [rtpInfo, setRtpInfo] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { rtpId } = useParams();

  useEffect(() => {
    setIsFetching(true);
    setTimeout(() => {
      const data = {
        id: rtpId,
        externalId: `EXT-${rtpId}`,
        createdAt: dayjs().subtract(1, "day").toISOString(),
        updatedAt: dayjs().toISOString(),
        source: "ecomm", // or 'cap'
        isImported: false,
        paymentResultId: `PAY-${id}`,
        ecommPaymentId: null,
        errorCode: null,
        errorMessage: null,
        posId: 220,
        transactionId: 630,
        phoneNumber: "+373 99 999 999",
        paymentDescription: "Оплата заказа",
        amount: "100.00",
        currency: "MDL",
        status: "pending",
        expiresAt: dayjs().add(1, "day").toISOString(),
      };

      setRtpInfo(data);
      setIsFetching(false);
    }, 1000);
  }, [id]);

  const navBarTitle = `${t("Request_to_pay")} #${rtpId}`;

  const breadcrumbs = {
    step1Label: t("Request_to_pay"),
    step1Url: "/app/requests",
    step2Label: `RTP #${rtpId}`,
  };

  if (redirect) {
    return <Redirect to="/app/requests" />;
  }

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {isFetching ? (
          <div className={styles.center_position}>
            <CircularProgress className="circular-progress" size={60} />
          </div>
        ) : (
          <>
            <div className={styles.info_block}>
              <div className={styles.info_block_title}>
                <span>{t("Details_request_payment")}</span>
              </div>
              <div>
                <div className={styles.info_block_input_container}>
                  <label>{t("ID")}</label>
                  <div className={styles.block_value}>{rtpInfo.id}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("External_request_ID")}</label>
                  <div className={styles.block_value}>{rtpInfo.externalId}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_creation_date")}</label>
                  <div className={styles.block_value}>
                    {dayjs(rtpInfo.createdAt).format("DD/MM/YYYY, HH:mm")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Last_update_date")}</label>
                  <div className={styles.block_value}>
                    {dayjs(rtpInfo.updatedAt).format("DD/MM/YYYY, HH:mm")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_source")}</label>
                  <div className={styles.block_value}>{rtpInfo.source}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("File_import_flag")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.isImported ? t("Yes") : t("NO")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_result_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.paymentResultId || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Ecomm_payment_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.ecommPaymentId || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Error_code")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.errorCode || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Error_message")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.errorMessage || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("POS ID")}</label>
                  <div className={styles.block_value}>
                    <Link
                      to={`/app/terminals/${rtpInfo.posId}`}
                      className={styles.no_underline}
                    >
                      POS-{rtpInfo.posId}
                    </Link>
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Transaction_ID_IDquest_ID")}</label>
                  <div className={styles.block_value}>
                    <Link
                      to={`/app/transactions/${rtpInfo.transactionId}`}
                      className={styles.no_underline}
                    >
                      TX-{rtpInfo.transactionId}
                    </Link>
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Client_phone_number")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.phoneNumber}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_description")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.paymentDescription}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_amount")}</label>
                  <div className={styles.block_value}>
                    {amountFormatter(rtpInfo.amount)}
                    <span className={styles.uppercase}>{rtpInfo.currency}</span>
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_status")}</label>
                  <div className={styles.block_value}>
                    {UseErrorLabel(rtpInfo.status, t)}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_expiry_date")}</label>
                  <div className={styles.block_value}>
                    {dayjs(rtpInfo.expiresAt).format("DD/MM/YYYY, HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </AppMainLayout>
  );
};

export default RTPDetails;
