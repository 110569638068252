import { createStore, applyMiddleware, combineReducers } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
import AuthReducer from './reducers/AuthReducer';
import TokenReducer from './reducers/TokenReducer';
import PrefsReducer from './reducers/PrefsReducer'
import MainReducer from './reducers/MainReducer'

const combinedReducer = combineReducers({
    AuthReducer,
    TokenReducer,
    PrefsReducer,
    MainReducer
});

const masterReducer = (state, action) => {
    return combinedReducer(state, action);
};

let middleware = [thunk, promise];

const composeEnhancers = composeWithDevTools({
    trace: true
});
const Store = createStore(masterReducer, composeEnhancers(applyMiddleware(...middleware)));
export type RootGetState = ReturnType<typeof Store.getState>

export default Store;