import React from 'react';
import { INotificationItemProps } from "./types"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./notification-item.module.scss"

const NotificationItem = ({ id, text, url, locale, localeOther, postFix }: INotificationItemProps) => {
    const { t } = useTranslation();

    const renderNotifUrl = (id, text, url) => {
        return (
            <>

                {url ? <a href={`${url}${id}`} style={{ color: "#fff", margin: "0px 5px" }}>
                    {text}
                    {` `}
                    #{id}
                </a> :
                    <span>{text || " "} #{id}</span>}
            </>
        );
    }
    // renderNotifUrl(id, text, url)
    return (
        <div className="notification">
            {t(locale)}
            {renderNotifUrl(id, text, url)}{` `}
            {t(localeOther)}
            {postFix}
        </div>
    );
};

export default NotificationItem;
