import React, { useEffect, useState, useRef } from "react"; // Добавили useRef
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./rtp.module.scss";
import { amountFormatter, capitalize, filtersRTP } from "../../constants";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import classNames from "classnames";
import CustomButton from "../../infrastructure/components/Button";
import Button from "@mui/material/Button";
import { PlusIcon } from "../../svg-icons/icon-plus";
import { AttachFileClipIcon } from "../../svg-icons/icon-attach-file-clip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RTP = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isFetching, setIsFetching] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("all");

  const [showAddNewRTPModal, setShowAddNewRTPModal] = useState(false);
  const [newRTPData, setNewRTPData] = useState({
    phoneNumber: "",
    amount: "",
    paymentDescription: "",
  });
  const [isAdding, setIsAdding] = useState(false);

  const [showImportModal, setShowImportModal] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchRTP();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [rows, searchText, filter]);

  const generateMoldavianPhoneNumber = () => {
    const areaCode = Math.floor(Math.random() * 100)
      .toString()
      .padStart(2, "0");
    const firstPart = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    const secondPart = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    return `(+373) ${areaCode}-${firstPart}-${secondPart}`;
  };

  const fetchRTP = () => {
    setIsFetching(true);

    setTimeout(() => {
      const data = Array.from({ length: 8 }, (_, index) => ({
        id: `REQ${index + 1}`,
        phoneNumber: generateMoldavianPhoneNumber(),
        paymentDescription: `Plata pentru comandă №${index + 1}`,
        amount: (Math.random() * 1000).toFixed(2),
        currency: "MDL",
        createdAt: dayjs().subtract(index, "day").toISOString(),
        status: [
          "created",
          "pending",
          "paid",
          "expired",
          "canceled",
          "denied",
          "error",
        ][index % 7],
        expiresAt: dayjs().add(1, "day").toISOString(),
      }));

      setRows(data);
      setIsFetching(false);
    }, 1500);
  };

  const applyFilters = () => {
    let filteredData = rows;

    if (filter !== "all") {
      filteredData = filteredData.filter((row) => row.status === filter);
    }

    if (searchText) {
      const lowerSearchText = searchText.toLowerCase();
      filteredData = filteredData.filter((row) =>
        row.phoneNumber.toLowerCase().includes(lowerSearchText)
      );
    }

    setFilteredRows(filteredData);
  };

  const isFormValid = () => {
    const { phoneNumber, amount, paymentDescription } = newRTPData;
    return phoneNumber && amount && paymentDescription;
  };

  const handleAddNewRTP = () => {
    setIsAdding(true);

    setTimeout(() => {
      const newRecord = {
        id: `REQ${rows.length + 1}`,
        phoneNumber: newRTPData.phoneNumber,
        paymentDescription: newRTPData.paymentDescription,
        amount: newRTPData.amount,
        currency: "MDL",
        createdAt: dayjs().toISOString(),
        status: "pending",
        expiresAt: dayjs().add(1, "day").toISOString(),
      };

      setRows([newRecord, ...rows]);
      setIsAdding(false);
      setShowAddNewRTPModal(false);
      setNewRTPData({ phoneNumber: "", amount: "", paymentDescription: "" });

      enqueueSnackbar(t("Request_successfully_added"), { variant: "success" });
    }, 1000);
  };

  const handleCloseModal = () => {
    setShowAddNewRTPModal(false);
    setNewRTPData({ phoneNumber: "", amount: "", paymentDescription: "" });
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
    setImportFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleImportRTP = () => {
    setIsImporting(true);

    setTimeout(() => {
      setIsImporting(false);
      setShowImportModal(false);
      setImportFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      enqueueSnackbar(t("Request_successfully_imported"), { variant: "success" });
    }, 1000);
  };

  const renderAddNewRTPModal = () => {
    return (
      <Dialog
        open={showAddNewRTPModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="add-new-rtp-dialog-description"
      >
        <DialogTitle>{t("New_rtp")}</DialogTitle>
        <DialogContent>
          <div className={styles.info_block_input_container}>
            <label htmlFor="phoneNumber" className={styles.label}>
              {t("Client_phone_number")}
            </label>
            <FormControl fullWidth>
              <InputMask
                mask="+373 99 999 999"
                value={newRTPData.phoneNumber}
                onChange={(e) =>
                  setNewRTPData({ ...newRTPData, phoneNumber: e.target.value })
                }
              >
                {(inputProps) => (
                  <OutlinedInput
                    {...inputProps}
                    placeholder={t("Client_phone_number")}
                  />
                )}
              </InputMask>
            </FormControl>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="amount" className={styles.label}>
              {t("Amount_request")}
            </label>
            <FormControl fullWidth margin="normal">
              <TextField
                placeholder={t("Amount_request")}
                type="number"
                value={newRTPData.amount}
                onChange={(e) =>
                  setNewRTPData({ ...newRTPData, amount: e.target.value })
                }
                required
              />
            </FormControl>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="paymentDescription" className={styles.label}>
              {t("Payment_description")}
            </label>
            <FormControl fullWidth margin="normal">
              <TextField
                placeholder={t("Payment_description")}
                value={newRTPData.paymentDescription}
                onChange={(e) =>
                  setNewRTPData({
                    ...newRTPData,
                    paymentDescription: e.target.value,
                  })
                }
                required
                multiline
                rows={4}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleAddNewRTP}
            variant="primary"
            disabled={!isFormValid() || isAdding}
            label={t("Send")}
          />
          <Button onClick={handleCloseModal} variant="text">
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderImportModal = () => {
    return (
      <Dialog
        open={showImportModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseImportModal}
        aria-describedby="import-rtp-dialog-description"
      >
        <DialogTitle>{t("Importing_file")}</DialogTitle>
        <DialogContent>
          <div className={styles.info_block_input_container_import}>
            <label htmlFor="importFile" className={styles.label}>
              {t("Select_file_import")}
            </label>
            <FormControl>
              <input
                type="file"
                accept=".csv, .xlsx, .xls"
                id="import-file-input"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => setImportFile(e.target.files[0])}
              />
              <Button
                variant="outlined"
                component="span"
                onClick={() =>
                  document.getElementById("import-file-input").click()
                }
                startIcon={<AttachFileClipIcon />}
              >
                {t("Choose_file")}
              </Button>
              {importFile && (
                <div className={styles.selected_file_name}>
                  {importFile.name}
                </div>
              )}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className={styles.modal_btn_wrapper}>
          <CustomButton
            onClick={handleImportRTP}
            variant="primary"
            disabled={!importFile || isImporting}
            label={t("Import")}
          >
            {isImporting && (
              <CircularProgress className="circular-progress" size={18} />
            )}
          </CustomButton>
          <Button onClick={handleCloseImportModal} variant="text">
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderFilterItems = () => {
    return filtersRTP.map((filterName) => (
      <button
        key={filterName}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: filter === filterName,
        })}
        onClick={() => setFilter(filterName)}
      >
        {filterName === "all" ? t("All") : t(`${capitalize(filterName)}`)}
      </button>
    ));
  };

  const renderRTPCells = () => {
    return [
      {
        field: "id",
        headerName: t("ID"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <Link to={`/app/requests/${row.id}`} className={styles.no_underline}>
            {row.id}
          </Link>
        ),
      },
      {
        field: "phoneNumber",
        headerName: t("Client_phone_number"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.phoneNumber}</span>
        ),
      },
      {
        field: "paymentDescription",
        headerName: t("Payment_description"),
        flex: 1.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.paymentDescription}</span>
        ),
      },
      {
        field: "amount",
        headerName: t("Payment_amount"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            {amountFormatter(row.amount)}
            <span className={styles.uppercase}>{row.currency}</span>
          </div>
        ),
      },
      {
        field: "createdAt",
        headerName: t("Request_creation_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>
            {dayjs(row.createdAt).format("DD/MM/YYYY, HH:mm")}
          </span>
        ),
      },
      {
        field: "status",
        headerName: t("Request_status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => UseErrorLabel(row.status, t),
      },
      {
        field: "expiresAt",
        headerName: t("Request_expiry_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>
            {dayjs(row.expiresAt).format("DD/MM/YYYY, HH:mm")}
          </span>
        ),
      },
    ];
  };

  return (
    <AppMainLayout navBarTitle={t("Request_to_pay")}>
      {renderAddNewRTPModal()}
      {renderImportModal()}
      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
          <InputLabel htmlFor="search-input">{t("Search")}</InputLabel>
          <OutlinedInput
            id="search-input"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FormControl>
        <div className={styles.button_group}>
          <CustomButton
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_rtp")}
            disabled={isFetching}
            onClick={() => setShowAddNewRTPModal(true)}
            className={styles.add_new_rtp}
          />
          <CustomButton
            variant="primary"
            icon={<AttachFileClipIcon />}
            label={t("Importing_file")}
            disabled={isFetching}
            onClick={() => setShowImportModal(true)}
            className={styles.add_new_rtp}
          />
        </div>
      </div>

      <div className={styles.filter_container}>{renderFilterItems()}</div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderRTPCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminal_table}
        rows={filteredRows}
      />
      <div className={styles.pagination_container}>
        {isFetching && (
          <CircularProgress className="circular-progress" size={18} />
        )}
      </div>
    </AppMainLayout>
  );
};

export default RTP;
