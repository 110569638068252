

import React from 'react';

export const PaymentsIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9883 6.638C12.6783 6.638 9.98828 9.328 9.98828 12.638C9.98828 15.948 12.6783 18.638 15.9883 18.638C19.2983 18.638 21.9883 15.948 21.9883 12.638C21.9883 9.328 19.2983 6.638 15.9883 6.638Z" fill="#A1ABB2" />
            <path d="M15.9883 4.638C11.5683 4.638 7.98828 8.218 7.98828 12.638C7.98828 17.058 11.5683 20.638 15.9883 20.638C20.4083 20.638 23.9883 17.058 23.9883 12.638C23.9883 8.218 20.4083 4.638 15.9883 4.638ZM15.9883 18.638C12.6783 18.638 9.98828 15.948 9.98828 12.638C9.98828 9.328 12.6783 6.638 15.9883 6.638C19.2983 6.638 21.9883 9.328 21.9883 12.638C21.9883 15.948 19.2983 18.638 15.9883 18.638ZM3.98828 12.638C3.98828 10.028 5.65828 7.808 7.98828 6.988V4.898C4.53828 5.788 1.98828 8.908 1.98828 12.638C1.98828 16.368 4.53828 19.488 7.98828 20.378V18.288C5.65828 17.468 3.98828 15.248 3.98828 12.638Z" fill="#A1ABB2" />
        </svg>
    );
};