import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import axios from "../../../api/services/Api";
// import { saveToken } from '../../../api/services/actionCreators/mainActionCreator';
import { otpInputs, onlyNumbersRegex, TIMER_RESEND_OTP, CONFIRM_SMS, RESEND_SMS } from '../../../constants';
import { IStepOtp } from "./types"
import { useTranslation } from 'react-i18next';
import Heading from "../../../infrastructure/components/Heading";
import CircularProgress from '@mui/material/CircularProgress';
import ButtonComponent from "../../../infrastructure/components/ButtonComponent";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { getToken } from '../../../api/selectors/mainSelector'
import { enqueueSnackbar } from 'notistack';
import {
    getTokenInHeaders,
} from "../../../api/selectors/tokenSelector";
import { Redirect } from "react-router-dom";
import {
    signIn,
    authStart,
    authEnd,
    setToken,
    setExpiredTime,
    setTokenInHeaders,
    setTokenExpired,
} from "../../../api/actionCreators/authActionCreator";
import {
    getIsAuthFetching,
} from "../../../api/selectors/authSelector";
import {
    storeToken,
    storeUser,
    storeExpiredTime,
    storeRefreshToken,
} from "../../../api/services/Auth";
import moment from "moment"
import OtpInput from "react-otp-input";

import './styles.scss';

const StepOtp = ({
    phoneNumber,
    loginConfirmationId,
    waitTimeToResend
}: IStepOtp) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const baseUrl = useSelector(getBaseUrl)
    const isAuthFetching = useSelector(getIsAuthFetching);
    const token = useSelector(getToken)
    const tokenInHeader = useSelector(getTokenInHeaders);

    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState("")
    const [digitsError, setDigitsError] = useState(false)
    const [hardBlock, setHardBlock] = useState(false)
    const [success, setSuccess] = useState(false)
    const [resendMessage, setResendMessage] = useState(false)
    const [showResendButton, setShowResendButton] = useState(false)
    const [newOtp, setNewOtp] = useState("");
    const [redirect, setRedirect] = useState(false);

    const [currentCount, setCount] = useState(waitTimeToResend);
    const timer = () => setCount(currentCount - 1);

    const onLogin = () => {
        setError("");
        dispatch(authStart());

        const params = {
            otpCode: newOtp,
            loginConfirmationId
        };

        axios
            .post("/cap/api/v1/auth/login/confirm", { ...params })
            .then((res: any) => {
                if (res.data.result === "success") {
                    const {
                        data: {
                            authTokens: {
                                accessToken,
                                accessTokenExpiresIn,
                                refreshToken
                            },
                            leftAttempts
                        },
                    } = res;
                    const tokenData = {
                        accessToken,
                        expiresIn: accessTokenExpiresIn,
                        refreshToken,
                    };
                    // connect(accessToken)
                    storeToken(accessToken);
                    storeRefreshToken(refreshToken);

                    const updatedDate = moment().add(30, "seconds");
                    storeExpiredTime(updatedDate);
                    storeUser(JSON.stringify({ phoneNumber }));

                    dispatch(signIn(JSON.stringify({ phoneNumber })));
                    dispatch(setToken(tokenData));
                    dispatch(setExpiredTime(updatedDate.toDate()));
                    dispatch(setTokenInHeaders(true));
                    dispatch(setTokenExpired(false));
                    setRedirect(true);
                } else if (res.data.result === "wrong_otp") {
                    setError(t("Wrong_otp"));
                } else if (res.data.result === "otp_limit_exceeded") {
                    enqueueSnackbar(t('Otp_limit_exceeded'), { variant: 'error' })
                    setHardBlock(true)
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (res.data.result === "otp_submit_limit_exceeded") {
                    enqueueSnackbar(t('Otp_submit_limit_exceeded'), { variant: 'error' })
                    setHardBlock(true)
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (res.data.result === "not_found") {
                    setError("Not_found")
                } else {
                    setError(t("Wrong_details"));
                }
                dispatch(authEnd());
            })
            .catch((error) => {
                setError(t("Wrong_details"));
                dispatch(authEnd());
            });
    };

    // useEffect(() => {
    //     const authCode = new URLSearchParams((new URL(window.location.href).search)).get('authCode')

    //     if (authCode && !token) {



    //         axios
    //             .post("/login/authcode", { authCode })
    //             .then((res: any) => {
    //                 const {
    //                     data: { accessToken, expiresIn, refreshToken },
    //                 } = res;
    //                 if (res.status === 200 || res.status === 201) {
    //                     const tokenData = {
    //                         accessToken,
    //                         expiresIn,
    //                         refreshToken,
    //                     };
    //                     storeToken(accessToken);
    //                     storeRefreshToken(refreshToken);

    //                     const updatedDate = moment().add(30, "seconds");
    //                     storeExpiredTime(updatedDate);
    //                     storeUser(JSON.stringify({ login: "" }));

    //                     dispatch(signIn(JSON.stringify({ login: "" })));
    //                     dispatch(setToken(tokenData));
    //                     dispatch(setExpiredTime(updatedDate.toDate()));
    //                     dispatch(authEnd());
    //                     dispatch(setTokenInHeaders(true));
    //                     dispatch(setTokenExpired(false));

    //                     window.location.reload();

    //                 } else {
    //                     setError(t("Wrong_details"));
    //                     dispatch(authEnd());
    //                 }
    //             })
    //             .catch((error) => {
    //                 setError(t("Wrong_details"));
    //                 dispatch(authEnd());
    //             });
    //     }
    // }, [])

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.code === "Enter" && !tokenInHeader) {
                event.preventDefault();
                onLogin();
            }
        };
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [phoneNumber]);

    useEffect(() => {

        if (currentCount <= 0) {
            setShowResendButton(true);
            return;
        }
        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [currentCount]);

    useEffect(() => {

        const onResendMessage = () => {

            setNewOtp("")
            setCount(waitTimeToResend)
            setShowResendButton(false)
            setResendMessage(false)
            setError("")

            const params = {
                loginConfirmationId,
                phoneNumber
            }

            axios.post(`/cap/api/v1/auth/login/otp-resend`, { ...params })
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        const resIsOk = res.data.result === RESEND_SMS.SUCCESS
                        if (res.data.result === RESEND_SMS.NO_ATTEMPTS_LEFT) {
                            enqueueSnackbar(t('No_attempts_left'))
                            //handleBack()
                        }
                        if (res.data.result === "otp_limit_exceeded") {
                            enqueueSnackbar(t('Otp_limit_exceeded'), { variant: 'error' })
                            setHardBlock(true)
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        }
                        !resIsOk && checkResponse(res.data.result)
                        if (resIsOk) {
                            enqueueSnackbar(t('Sent'), { variant: 'success' })
                        }
                    } else {
                        setError("OTP_Error_message")
                        setNewOtp("")
                    }
                })
        }

        resendMessage && onResendMessage()
    }, [resendMessage])

    const checkResponse = (response) => {
        let text

        switch (response) {
            case CONFIRM_SMS.INVALID_OTP:
                text = "Invalid_otp";
                break;
            case CONFIRM_SMS.NO_ATTEMPTS_LEFT:
                text = "Otp_submit_limit_exceeded";
                break;
            case CONFIRM_SMS.OTP_EXPIRED:
                text = "Otp_expired";
                break;
            case CONFIRM_SMS.OTP_NOT_FOUND:
                text = "Otp_not_found";
                break;
            case RESEND_SMS.TO_EARLY:
                text = "Too_early";
                break;

            default:
                break;
        }

        setError(text)
    }

    const otpCheckInProcess = () => {
        return (
            <div className="otp-process">
                <h3>{t('Check_entered_otp')}</h3>
                <CircularProgress size={18} className="circular-yellow" />
            </div>
        )
    }

    const otpCheckSuccess = () => {
        return (
            <div className="otp-success">
                <DoneIcon color="success" />
            </div>
        )
    }

    const otpCheckFailed = () => {
        return (
            <div className="otp-failed">
                <ErrorIcon color="error" />
                {/* <label>{t('OTP_Error_message')}</label> */}
                <label>{t(error)}</label>
            </div>
        )
    }

    const digitsOtpError = () => {
        return (
            <div className="otp-failed">
                <ErrorIcon color="error" />
                <label>{t('Enter_only_numbers')}</label>
            </div>
        )
    }

    const setOtp = (value) => {
        setError("");
        setDigitsError(false);

        const isValid = onlyNumbersRegex(value);
        if (isValid || value === "") {
            setNewOtp(value);
        } else {
            setDigitsError(true);
        }
    };



    const renderOtpInputs = () => {
        return (
            <OtpInput
                value={newOtp}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                shouldAutoFocus
                inputStyle="text-input-otp"
                renderInput={(props, i) => {
                    return (
                        <input
                            {...props}
                            type={"number"}
                            disabled={hardBlock}
                            inputMode={"numeric"}
                        // disabled={ newOtp.length < i}
                        />
                    );
                }}
            />
        );
    };

    const renderFormSection = () => {
        return (

            <div className="user-data-container">
                <div className="step-one-substeps-container">
                    {redirect && <Redirect to="/app/merchants" />}
                    {isFetching ? otpCheckInProcess() :
                        <div className="otp-container">
                            <Heading headingFirst={t("Otp_title")} />
                            <div className="subtitle">
                                {t("Sended_phone")}{phoneNumber}
                            </div>

                            <div className="justify-align-center">
                                {renderOtpInputs()}
                            </div>
                            <div className="resend-container">
                                {showResendButton ?
                                    <span onClick={() => setResendMessage(true)}>{t("Send_sms_one_more_time")}</span> :
                                    <b>{t("Can_send_after")} {currentCount} {t("Sec")}</b>
                                }
                            </div>
                            <ButtonComponent
                                title={t("Enter")}
                                onClick={() => isAuthFetching || newOtp.length !== 6 ? {} : onLogin()}
                                disabled={isAuthFetching || newOtp.length !== 6}
                                icon={
                                    (isAuthFetching) && <CircularProgress className="circular-progress" size={18} />
                                }
                            />

                        </div>
                    }
                    {success && otpCheckSuccess()}
                    {error && otpCheckFailed()}
                    {digitsError && digitsOtpError()}
                </div>
            </div>

        )
    }

    return (
        <div className="step-container">
            {renderFormSection()}
        </div>
    );
};

export default StepOtp;