import React from 'react';

export const TwoArrowsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.13539 11.9471L3.35539 14.7371C3.16539 14.9371 3.16539 15.2471 3.35539 15.4471L6.13539 18.2371C6.44539 18.5571 6.98539 18.3271 6.98539 17.8871V16.0871H12.9954C13.5454 16.0871 13.9954 15.6371 13.9954 15.0871C13.9954 14.5371 13.5454 14.0871 12.9954 14.0871H6.98539V12.2971C6.98539 11.8471 6.44539 11.6271 6.13539 11.9471ZM20.6454 8.73706L17.8654 5.94706C17.5554 5.62706 17.0154 5.85706 17.0154 6.29706V8.08706H10.9954C10.4454 8.08706 9.99539 8.53706 9.99539 9.08706C9.99539 9.63706 10.4454 10.0871 10.9954 10.0871H17.0054V11.8771C17.0054 12.3271 17.5454 12.5471 17.8554 12.2271L20.6354 9.43706C20.8354 9.24706 20.8354 8.92706 20.6454 8.73706Z" fill="#A1ABB2" />
        </svg>


    );
};