/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { PlusIcon } from "../../svg-icons/icon-plus";
import classNames from "classnames"
import styles from "./terminals.module.scss";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getTerminalPageSize } from "../../api/selectors/mainSelector";
import { pages, getModalText } from "../../constants";
import { UseErrorLabel } from "../../hooks/useErrorLabel"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDebounce from '../../hooks/useDebounce';
import { enqueueSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import { TransitionProps } from "@mui/material/transitions";
import Select from "@mui/material/Select";
import NotificationItem from "../../infrastructure/components/NotificationItem"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Terminals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  // const [isFetchStores, setIsFetchStores] = useState(true);
  const [searchText, setSearchText] = useState("");
  // const [redirectId, setRedirectId] = useState(null);
  // const [terminalStatus, setTerminalStatus] = useState("all");
  const [actionState, setActionState] = useState("");
  const [actionRowId, setActionRowId] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [isActionStateFetching, setIsActionStateFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rows, setRows] = useState([]);
  //   const [filter, setFilter] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getTerminalPageSize);

  useEffect(() => {

    if (actionState === 'assign') {
      const fetchStores = async () => {
        // setIsFetchStores(true)
        try {
          const { status, response } = await apiRequest(
            "get",
            `/cap/api/v1/store/brief`,
            null
          );

          if (status === 200 || status === 201) {
            const {
              data: {
                storeList,
              },
            } = response;

            setStoreList(storeList || []);
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
          } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          }
        } catch (error) {
          console.error(error);
        } finally {
          // setIsFetchStores(false);
        }
      }

      fetchStores()
    }
  }, [actionState])


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setAnchorEl(null)
          setTimeout(() => {
            setAnchorEl(null)
          }, 200);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const fetchTerminals = useCallback(async (searchText = "", currentPage, pageSize) => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/cap/api/v1/pos?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}&status=`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            terminalList,
            paginationInfo: { totalCount },
          },
        } = response;
        setRows(terminalList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Failed to fetch sellers:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useDebounce(() => {
    fetchTerminals(searchText, currentPage, pageSize)
  }, [searchText, currentPage, pageSize, fetchTerminals, token, accessToken], 800);


  const handleOpen = (id) => {
    setAnchorEl(id);
  };

  const renderPostFix = () => {
    return (
      <a href={`/app/stores/${selectedStoreId}`} style={{ color: "#fff", margin: "0px 5px" }}>
        #{selectedStoreId}
      </a>
    )
  }

  const onSellerAction = async ({ method, apiUrl, url, locale, localeOther, postFix }) => {
    setIsActionStateFetching(true)
    const params = {
      storeId: selectedStoreId
    }
    try {
      const { status } = await apiRequest(
        method,
        apiUrl,
        selectedStoreId ? { ...params } : null
      );

      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar(<NotificationItem
          id={actionRowId}
          postFix={postFix ? renderPostFix() : ""}
          // text={selectedStoreId}
          {...{ url, locale, localeOther }}
        />, { variant: "success" });
        fetchTerminals(searchText, currentPage, pageSize)
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsActionStateFetching(false);
      setActionRowId("")
      setActionState("")
      setSelectedStoreId(null)
    }
  }

  const renderActionModal = () => {
    const textLabels = getModalText(actionState, actionRowId, t)

    // const ifNeedToDisable = actionState === "assign" ? selectedStoreId ===  : false 
    return (
      <Dialog
        open={!!actionState}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setActionState("")}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {textLabels.title}
          </DialogTitle>
          {actionState === "assign" ?

            <DialogContent className={styles.modal_content}>
              <DialogContentText
                id="alert-dialog-slide-description"
                className={styles.logout_header}
              >
                <div
                  className={classNames({
                    [styles.info_block_input_container]: true,
                  })}
                >
                  <label htmlFor="merchantId">{t("Store_id")}</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Store")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="merchantId"
                      value={selectedStoreId}
                      label={t("Store")}
                      onChange={(e) =>
                        setSelectedStoreId(e.target.value)
                      }
                    >
                      {storeList.map((i) => (
                        <MenuItem value={i.id}>
                          #{i.id} / {i.storeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

              </DialogContentText>
            </DialogContent>
            :
            <DialogContent className={styles.modal_content}>
              <DialogContentText
                id="alert-dialog-slide-description"
                className={styles.logout_header}
              >
                {textLabels.description} #{actionRowId}
              </DialogContentText>
            </DialogContent>}



          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => isActionStateFetching ? {} : onSellerAction(textLabels)}
              label={textLabels.btnLabel}
              variant={actionState === "delete" ? "delete" : "ghost"}
              disabled={isActionStateFetching || !selectedStoreId}
              className={classNames({
                [styles.disabled_btn]: isActionStateFetching || !selectedStoreId,
                [`${styles[`btn_${actionState}`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isActionStateFetching && <CircularProgress className="circular-progress" size={18} />
              }
            />
            <Button
              onClick={() => closeModalAndResetModalData()}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const closeModalAndResetModalData = () => {
    setSelectedStoreId(null)
    setActionState("")
  }

  const sellersSetActions = (action, { id, storeId }) => {
    setActionState(action)
    setActionRowId(id)
    const sId = action === "unassign" ? storeId : null
    setSelectedStoreId(sId)
  }


  const actionAssignTerminal = ({ id, storeId }) => {
    setActionState("assign");
    setActionRowId(id);
    setSelectedStoreId(storeId)
  }

  const renderActionDotts = (row) => {
    const { id, state } = row

    return (
      <div style={{ position: "relative", cursor: "pointer" }} ref={anchorEl !== null ? wrapperRef : null}>
        {state !== "unattached" ? <MoreVertIcon
          onClick={() => handleOpen(id)}
          aria-labelledby={`demo-positioned-button-${id}`}
          id={`fade-button-${id}`}
          aria-controls={anchorEl === id ? `fade-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl === id ? 'true' : undefined}
        /> :
          <div className={styles.assign_to_store_btn} onClick={() => { setActionState("assign"); setActionRowId(id) }}>
            {t('Assign_to_store')}
          </div>}

        <div
          className={classNames({
            [`${styles.menu_actions}`]: true,
            [`${styles.show}`]: anchorEl === id,
          })}
        >
          <div onClick={() => actionAssignTerminal(row)}>{t('Assign_to_store')}</div>
          <div onClick={() => sellersSetActions("unassign", row)}>{t('Unassign_to_store')}</div>
        </div>
      </div>
    )
  }
  const renderLicensesCells = () => {
    return [
      {
        field: "id",
        headerName: t("POS ID"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/terminals/${row.id}`}
              className={classNames({
                [styles.no_underline]: true,
                [styles.is_link]: true,
              })}
            >
              {row.id || "-"}
            </Link>
          );
        },
      },
      {
        field: "merchantIdno",
        headerName: t("Merchant_idno"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return row.merchantIdno;
        },
      },
      {
        field: "terminalId",
        headerName: t("Terminal_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/terminals/${row.id}`}
              className={classNames({
                [styles.no_underline]: true,
                [styles.is_link]: true,
              })}
            >
              {row.terminalId || "-"}
            </Link>
          );
        },
      },
      {
        field: "type",
        headerName: t("Terminal_type"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          const typeLabels = {
            mia_pos: "Mia POS",
            ecomm: "Ecomm",
            rtp: "RTP",
          };
          return typeLabels[row.type] || "-";
        },
      },
      {
        field: "storeId",
        headerName: t("Store_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.storeId}`}
              className={classNames({
                [styles.no_underline]: true,
                [styles.is_link]: true,
              })}
            >
              {row.storeId || "-"}
            </Link>
          );
        },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.storeId}`}
              className={classNames({
                [styles.no_underline]: true,
                [styles.is_link]: true,
              })}
            >
              {row.storeName || "-"}
            </Link>
          );
        },
      },
      {
        field: "mccCode",
        headerName: t("Mcc_code"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return (
            row.mccCode
          );
        },
      },

      {
        field: "state",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return UseErrorLabel(row.state, t);
        },
      },
      // {
      //   field: "registrationDate",
      //   headerName: t("Registration_date"),
      //   flex: 1,
      //   headerClassName: styles.table_header,
      //   cellClassName: styles.table_cell_date,
      //   renderCell: ({ row }) => {
      //     return moment(row.registrationDate).format("DD-MM-YYYY HH:mm");
      //   },
      // },

      {
        field: "actions",
        headerName: "#",
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return renderActionDotts(row);
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.TERMINAL_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Terminals")}>
      {/* {(redirectId === 0 || redirectId > 0) && (
        <Redirect to={`/app/merchants/${redirectId}`} />
      )} */}
      {!!actionState && renderActionModal()}
      <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          {t("Search")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<SearchIcon />}
          label={t("Search")}
          value={searchText}
          onChange={(e) => { setCurrentPage(0); setSearchText(e.target.value) }}
        />
      </FormControl>
      {/* <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div> */}

      <DataTableComponent
        // onCellClick={(row) => onCellClick(row)}
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminals_table}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}
    </AppMainLayout>
  );
};

export default Terminals;
