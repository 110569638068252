/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
// import { Button } from "finergy-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { apiRequest } from "../../api/services/Api";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import moment from "moment";
import { PlusIcon } from "../../svg-icons/icon-plus";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import Button from "../../infrastructure/components/Button";
import useDebounce from "../../hooks/useDebounce";
import styles from "./stores.module.scss";
import { enqueueSnackbar } from "notistack";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getTerminalPageSize } from "../../api/selectors/mainSelector";
import { pages } from "../../constants";

const Stores = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  console.log('rows', rows);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getTerminalPageSize);

  const fetchStores = async (searchText, currentPage, pageSize) => {
    setIsFetching(true);
    try {
      const { response, response: { status } } = await apiRequest(
        "get",
        `/cap/api/v1/store?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            storeList,
            paginationInfo: { totalCount },
          },
        } = response;

        setRows(storeList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch terminals:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useDebounce(
    () => {
      fetchStores(searchText, currentPage, pageSize);
    },
    [searchText, currentPage, pageSize, token, accessToken],
    800
  );

  const renderStoresCells = () => {
    return [
      {
        field: "id",
        headerName: t("Store_id"),
        width: 100,
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.id}`}
              className={styles.no_underline}
            >
              {row.id}
            </Link>
          );
        },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.id}`}
              className={styles.no_underline}
            >
              {row.storeName}
            </Link>
          );
        },
      },
      {
        field: "storeAddress",
        headerName: t("Address"),
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_address,
        renderCell: ({ row }) => {
          return (
            <span
              className={styles.no_color}
            >
              {row.storeAddress}
            </span>
          );
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        cellClassName: styles.table_cell_date,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return <span className={styles.no_color}>{moment(row.whenCreated).format("DD/MM/YYYY, HH:mm")}</span>
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    setIsFetching(true);
    dispatch(setPageSize(pages.TERMINAL_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Stores")}>


      <div className={styles.customer_page_bottom_container}>

        <FormControl fullWidth >
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>

        <Link to="/app/stores/new" className={styles.no_underline}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_store")}
            disabled={isFetching}
          />
        </Link>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderStoresCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminal_table}
        {...{ rows }}
      />

      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}

     
    </AppMainLayout>
  );
};

export default Stores;
