import _ from "lodash"
import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import { paginationSize } from "../../../constants/data"

import "./styles.scss"

const defaultChunkSize = 4

export default (props) => {
  const { onClick = _.noop, className, chunks, onChangePageSize } = props
  const { t } = useTranslation()
  const [, updateState] = useState()
  const forceUpdate = () => updateState({})
  window.onresize = _.debounce(forceUpdate, 300)

  const renderPagSize = () => {
    const result = paginationSize.map((p, i) => {
      return (
        <MenuItem value={p} key={i}>
          {p}
        </MenuItem>
      )
    })

    return result
  }

  const getPages = ({ total = 0, pageIndex = 0, pageSize = 20 }) => {
    let chunkSize = chunks || defaultChunkSize

    if (window.innerWidth < 704) chunkSize = 7
    if (window.innerWidth < 640) chunkSize = 6
    if (window.innerWidth < 600) chunkSize = 5
    if (window.innerWidth < 555) chunkSize = 4
    if (window.innerWidth < 514) chunkSize = 3
    const totalPages = Math.ceil(total / pageSize)
    const isFirstPage = total === 0 || pageIndex === 0
    const isLastPage = total === 0 || pageIndex === totalPages - 1

    // const [chunkSize = defaultChunkSize, setChunkSize] = useState()
    const totalChunks = Math.ceil(totalPages / chunkSize)
    const chunkIndex = Math.floor(pageIndex / chunkSize)
    const isFirstChunk = totalChunks === 0 || chunkIndex === 0
    const isLastChunk = totalChunks === 0 || chunkIndex === totalChunks - 1

    const pages = []
    const meta = []

    const getPageBlock = (block) => {
      if (block === "prev") {
        return pageIndex - 1 //* chunkSize
      } else {
        return pageIndex + 1 //* chunkSize
      }
    }

    if (total > 0) {
      const item = (label, index, cssBase, disabled) => ({
        label,
        index,
        className: classnames(cssBase, { disabled }),
      })

      //meta.push(item("<", 0, 'first', isFirstPage))
      meta.push(
        item("", isFirstPage ? -1 : getPageBlock("prev"), "prev", isFirstPage)
      )
      // for (let j = 0; j <= chunkSize; j++) {
      //   const index = j + chunkIndex * chunkSize
      //   if (index >= totalPages) break

      //   meta.push(
      //     item(index + 1, index, {
      //       "page-style": index !== pageIndex,
      //       current: index === pageIndex,
      //     })
      //   )
      // }
      meta.push(
        item("", isLastPage ? -1 : getPageBlock("next"), "next", isLastPage)
      )
      //meta.push(item(">", totalPages - 1, 'last', isLastPage))

      meta.forEach((x, j) => {
        pages.push(
          <div key={j} className={classnames(x.className, "pagination-item")}>
            <Link
              to="#"
              onClick={(event) => {
                // stop other events
                event.preventDefault()
                // exit if item is disabled
                if (x.className.indexOf("disabled") !== -1) return
                // handle click
                x.index >= 0 && onClick(x.index)
                // scroll to the top
                //window.scrollTo(0, 0)
              }}
            >
              {x.label}
            </Link>
          </div>
        )
      })
    }
    return pages
  }

  const pages = getPages(props)

  const { pageIndex, pageSize, total } = props

  const fromTo = pageIndex * pageSize
  const curSize = fromTo + pageSize

  return (
    <Fragment>
      <div className="pagination-container">
        <div className="select-page-size">
          <label>{t("Select_page_size")}</label>
          <FormControl>
            <Select
              className="select-lang-selector"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageSize}
              onChange={(e) => onChangePageSize(e.target.value)}
            >
             {renderPagSize()}
            </Select>
          </FormControl>
        </div>
        <div className="pagination-info">
          {total > 0 ? fromTo + 1 : 0} - {curSize > total ? total : curSize} of {total}
        </div>
        {pages.length > 0 && (
          <div
            className={classnames("full-width", "ui-pagination", className)}
            justify="center"
            wrap="nowrap"
          >
            <div>{pages.map((x) => x)}</div>
          </div>
        )}
      </div>
    </Fragment>
  )
}
