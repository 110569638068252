// Used for manipulations with local storage
const SESSION_TOKEN = "_session_token";
const USER_DATA = "_user_data";
const SESSION_EXPIRED_TIME = "_expired_time"
const REFRESH_TOKEN = "_refresh_token"
const FETCH_TOKEN = "_fetch_token"
const MAINTENANCE_MODE = "_maintenance_mode"

export function storeToken(token) {
    localStorage.setItem(SESSION_TOKEN, token);
}
export function setFetchRefreshToken(action) {
    localStorage.setItem(FETCH_TOKEN, action);
}
export function storeExpiredTime(time) {
    localStorage.setItem(SESSION_EXPIRED_TIME, time);
}

export function storeRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token);
}
export function storeUser(userData) {
    localStorage.setItem(USER_DATA, userData);
}

export function getToken() {
    return localStorage.getItem(SESSION_TOKEN);
}

export function getLocalstorageToken() {
    return localStorage.getItem(SESSION_TOKEN);
}

export function getExpiredTime() {
    return localStorage.getItem(SESSION_EXPIRED_TIME);
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
}

export function getUserData() {
    return localStorage.getItem(USER_DATA);
}

export function isTokenPresent() {
    return localStorage.getItem(SESSION_TOKEN) !== null;
}

export function removeToken() {
    localStorage.removeItem(SESSION_TOKEN);
}

export function removeExpiredTime() {
    localStorage.removeItem(SESSION_EXPIRED_TIME);
}

export function removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN);
}

export function removeUserData() {
    localStorage.removeItem(USER_DATA);
}


export function setMaintenanceModeStatus(status) {
    localStorage.setItem(MAINTENANCE_MODE, status);
}

export function getMaintenanceModeStatus() {
    return localStorage.getItem(MAINTENANCE_MODE);
}

export function removeMaintenanceModeStatus() {
    localStorage.removeItem(MAINTENANCE_MODE);
}