import React from 'react';
import { createBrowserHistory } from 'history';
import App from './App.tsx';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Router } from 'react-router-dom';
import Store from './api/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from "notistack";

import './App.scss';

import './assets/fonts/onest/Onest-Regular.ttf';
import './assets/fonts/onest/Onest-Thin.ttf';
import './assets/fonts/onest/Onest-Black.ttf';
import './assets/fonts/onest/Onest-Bold.ttf';
import './assets/fonts/onest/Onest-ExtraBold.ttf';
import './assets/fonts/onest/Onest-Light.ttf';
import './assets/fonts/onest/Onest-Medium.ttf';

const container = document.getElementById('root');
const root = createRoot(container);
const history = createBrowserHistory();

root.render(

    <Provider store={Store}>
        <SnackbarProvider autoHideDuration={5000} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}  >
            <Router history={history}>
                {/* <React.StrictMode> */}
                <BrowserRouter>
                    <App />
                </BrowserRouter>
                {/* </React.StrictMode> */}
            </Router>
        </SnackbarProvider>
    </Provider>
);