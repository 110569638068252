import React from 'react';

export const ArrowIcon = ({ onClick }) => {
    return (
        <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7915 7.0051H3.62148L8.50148 2.1251C8.89148 1.7351 8.89148 1.0951 8.50148 0.705098C8.11148 0.315098 7.48148 0.315098 7.09148 0.705098L0.501484 7.2951C0.111484 7.6851 0.111484 8.3151 0.501484 8.7051L7.09148 15.2951C7.48148 15.6851 8.11148 15.6851 8.50148 15.2951C8.89148 14.9051 8.89148 14.2751 8.50148 13.8851L3.62148 9.0051H14.7915C15.3415 9.0051 15.7915 8.5551 15.7915 8.0051C15.7915 7.4551 15.3415 7.0051 14.7915 7.0051Z" fill="#1D2126" />
        </svg>


    );
};