

import React from 'react';

export const SearchIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.965 14.4716H16.175L15.895 14.2016C17.095 12.8016 17.715 10.8916 17.375 8.86157C16.905 6.08157 14.585 3.86157 11.785 3.52157C7.55498 3.00157 3.99498 6.56157 4.51498 10.7916C4.85498 13.5916 7.07498 15.9116 9.85498 16.3816C11.885 16.7216 13.795 16.1016 15.195 14.9016L15.465 15.1816V15.9716L19.715 20.2216C20.125 20.6316 20.795 20.6316 21.205 20.2216C21.615 19.8116 21.615 19.1416 21.205 18.7316L16.965 14.4716ZM10.965 14.4716C8.47498 14.4716 6.46498 12.4616 6.46498 9.97157C6.46498 7.48157 8.47498 5.47157 10.965 5.47157C13.455 5.47157 15.465 7.48157 15.465 9.97157C15.465 12.4616 13.455 14.4716 10.965 14.4716Z" fill="#A1ABB2" />
        </svg>
    );
};