import React from 'react';

export const EmptyPageIcon = () => {
    return (
        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.9993 100.833C80.3124 100.833 100.833 80.3131 100.833 55.0001C100.833 29.687 80.3124 9.16675 54.9993 9.16675C29.6863 9.16675 9.16602 29.687 9.16602 55.0001C9.16602 80.3131 29.6863 100.833 54.9993 100.833Z" stroke="#A1ABB2" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M41.6621 41.2499C42.7397 38.1867 44.8666 35.6037 47.6661 33.9585C50.4656 32.3132 53.757 31.7117 56.9575 32.2607C60.158 32.8097 63.0609 34.4736 65.152 36.9578C67.2432 39.4419 68.3878 42.5861 68.3829 45.8333C68.3829 54.9999 54.6329 59.5833 54.6329 59.5833V65" stroke="#A1ABB2" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M55 77.9166H55.0458" stroke="#A1ABB2" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};